import { useState } from "react";
import { CatCard } from "./HomePage";
import TelephoneNumberHiden from "./TelephoneNumberHiden";
import { calculateAge, formatDate, formatPrice, getSex, getStringValue } from "./lib/FormatData";

interface PetFullCardProps {
    petCard: CatCard;
};

export type Parameter = {
    name: string;
    value: string | number;
};

export default function PetFullCardText(props: PetFullCardProps): JSX.Element {
    const data: Parameter[] = [
        { name: "Кличка",                       value: getStringValue(props.petCard.name)},
        { name: "Порода",                       value: getStringValue(props.petCard.breed)},
        { name: "Окрас",                        value: getStringValue(props.petCard.coat)},
        { name: "Пол",                          value: getSex(getStringValue(props.petCard.sex), props.petCard.is_neuter)},
        { name: "Дата рождения",                value: formatDate(getStringValue(props.petCard.birthday))},
        { name: "Отец",                         value: getStringValue(props.petCard.sir_name)},
        { name: "Окрас отца",                   value: getStringValue(props.petCard.sir_coat)},
        { name: "Мать",                         value: getStringValue(props.petCard.dam_name)},
        { name: "Окрас матери",                 value: getStringValue(props.petCard.dam_coat)},
        { name: "Цена в любимцы",               value: formatPrice(getStringValue(props.petCard.pet_price))},
        { name: "Цена для племенной работы",    value: formatPrice(getStringValue(props.petCard.breed_price))},
        { name: "Статус",                       value: getStringValue(props.petCard.sale_status)},
        { name: "Описание",                     value: getStringValue(props.petCard.adv_text)},
    ]

    const cardText = data.map((item, index) => (
        (item.value !== '') ?
            <tr className="row" key={index}>
                <td className="cell1">{item.name}</td>
                <td className="cell2">{item.value}</td>
            </tr> : <></>
    ));

    return (
        <>
            <table className="styled-table">
                <tbody>
                    {cardText}
                </tbody>
            </table>
        </>
    );
}

/*
        { name: "Пол",                          value: getSex(getStringValue(props.petCard.sex), props.petCard.is_neuter)},


                <thead>
                    <tr>
                        <th>Parameter</th>
                        <th>Value</th>
                    </tr>
                </thead>
*/

/*

    function getStringValue (value: string | null): string {
        return value ? value.trim() : ''
    }

    function formatPrice(price: string, localString: string = "ru-RU"): string {
        const numericValue = parseFloat(price);
        if (isNaN(numericValue)) {
            return price;
        }
        return numericValue.toLocaleString(localString, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    function getSex(sex: string, isNueter: boolean): string {
        if (sex.trim().length == 0) {
            return "";
        } else {
            if (isNueter) return sex + '/NEUTER'
            return sex;
        }
    }

    function formatDate(dateString: string, localString: string = "ru-RU"): string {
        const isValidDate = (dateString: string): boolean => {
            const date = new Date(dateString);
          
            // Проверяем, является ли дата объектом Date и не "Invalid Date"
            return !isNaN(date.getTime());
        };
        
        if (isValidDate(dateString)) {
            const date = new Date(dateString); // Преобразуем строку в объект Date
      
            return new Intl.DateTimeFormat(localString, {
              day: "2-digit",    // День с ведущим нулём
              month: "long",     // Полное название месяца
              year: "numeric",   // Полное отображение года
            }).format(date) //+ " г."; // Добавляем "г." в конец
        } else {
            return '';
        }
    };


*/