import { Card } from "./HomePage";

export default function NurseryPetShortCard(props: { petCard: Card }): JSX.Element {
    const photo: JSX.Element =
        <>
            {(props.petCard.photos.length > 0) ? 
                <img alt={props.petCard.photos[0].src} 
                    src={ props.petCard.photos[0].src} 
                    className="card_item_image"
                /> : <></>
            }
        </>
    return (
        <div className="nursery_pet_short_card">
            {photo}
            <p>{props.petCard.name}</p>
            <p>{props.petCard.breed}</p>
            <p>{props.petCard.sex}</p>
            <p>{'Дата рождения: '}{props.petCard.dob}</p>
        </div>
    );
}