interface ConditionProps {
    text: string;
    status: boolean
}

export default function CheckPasswordItem(props: ConditionProps): JSX.Element {

    return (
        <>
            <div className={(props.status) ? "completed" : "not_completed" }>
                    { props.text }
            </div>
        </>
    );
}