import { Card } from "../HomePage";

export const veteranList: Card[] = [
    {
        id: 9,
        name: "Ch.WCF Big Eared Miracle Nissa",
        breed: "Devon Rex",
        dob: "05 мая 2015 г.",
        price: "",
        photos: [
            {
                id: 0,
                src: "photo/ph17021.jpeg",
                alt: "ph17021",
            },
        ],
        description: "",
        telNumber: "+7-918-311-55-29",
        sex: "FEMALE"
    },
    {
        id: 10,
        name: "Ch.WCF Big Cullian's Dolphin Drop Fire",
        breed: "Devon Rex",
        dob: "28 декабря 2012 г.",
        price: "",
        photos: [
            {
                id: 0,
                src: "photo/ph2016.jpeg",
                alt: "ph2016",
            },
        ],
        description: "",
        telNumber: "+7-918-311-55-29",
        sex: "FEMALE"
    },
    {
        id: 11,
        name: "Ch.WCF Great Elf Oyama Masutasu",
        breed: "Devon Rex",
        dob: "09 октября 2018 г.",
        price: "",
        photos: [
            {
                id: 0,
                src: "photo/ph63941.jpeg",
                alt: "ph63941",
            },
        ],
        description: "",
        telNumber: "+7-918-311-55-29",
        sex: "MALE"
    },
    {
        id: 12,
        name: "Ch.WCF Wonderland's Joselyn",
        breed: "Devon Rex",
        dob: "12 июня 2013 г.",
        price: "",
        photos: [
            {
                id: 0,
                src: "photo/ph3792.jpg",
                alt: "ph3792",
            },
        ],
        description: "",
        telNumber: "+7-918-311-55-29",
        sex: "FEMALE"
    },
    {
        id: 13,
        name: "Ch.WCF Big Eared Miracle Gulia",
        breed: "Devon Rex",
        dob: "12 июня 2013 г.",
        price: "",
        photos: [
            {
                id: 0,
                src: "photo/P1010232.jpeg",
                alt: "P1010232",
            },
        ],
        description: "",
        telNumber: "+7-918-311-55-29",
        sex: "FEMALE"
    },
];

export const nuterList: Card[] = [
    {
        id: 5,
        name: "Big Eared Miracle Zara",
        breed: "Devon Rex",
        dob: "23 мая 2023 г.",
        price: "В добрые руки",
        photos: [
            {
                id: 0,
                src: "photo/ph18.jpeg",
                alt: "ph10",
            },
            {
                id: 1,
                src: "photo/ph20.jpeg",
                alt: "ph11",
            },
            {
                id: 2,
                src: "photo/ph21.jpeg",
                alt: "ph12",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-05",
        sex: "FEMALE"
    },
    {
        id: 9,
        name: "Big Eared Miracle Zlata",
        breed: "Devon Rex",
        dob: "23 мая 2023 г.",
        price: "В добрые руки",
        photos: [
            {
                id: 0,
                src: "photo/ph32.jpeg",
                alt: "ph10",
            },
            {
                id: 1,
                src: "photo/ph20.jpeg",
                alt: "ph11",
            },
            {
                id: 2,
                src: "photo/ph21.jpeg",
                alt: "ph12",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-05",
        sex: "FEMALE"
    },
    {
        id: 6,
        name: "Big Eared Miracle Urfin",
        breed: "Devon Rex",
        dob: "01 ноября 2022 г.",
        price: "В добрые руки",
        photos: [
            {
                id: 0,
                src: "photo/ph202412060091.jpeg",
                alt: "ph202412060091",
            },
            {
                id: 1,
                src: "photo/ph11.jpeg",
                alt: "ph11",
            },
            {
                id: 2,
                src: "photo/ph12.jpeg",
                alt: "ph12",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-05",
        sex: "MALE"
    },
];

export const kittenList: Card[] = [
    {
        id: 4,
        name: "Big Eared Miracle Elvis",
        breed: "Devon Rex",
        dob: "03 октября 2024 г.",
        price: "50 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph10.jpeg",
                alt: "ph20241206",
            },
            {
                id: 1,
                src: "photo/ph2024120631.jpeg",
                alt: "ph202412061",
            }
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-04",
        sex: "MALE"
    },
    {
        id: 5,
        name: "Big Eared Miracle Don Semen",
        breed: "Devon Rex",
        dob: "05 мая 2024 г.",
        price: "В добрые руки",
        photos: [
            {
                id: 0,
                src: "photo/ph01.jpeg",
                alt: "ph10",
            },
            {
                id: 1,
                src: "photo/ph11.jpeg",
                alt: "ph11",
            },
            {
                id: 2,
                src: "photo/ph12.jpeg",
                alt: "ph12",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-05",
        sex: "MALE"
    },
    {
        id: 6,
        name: "Big Eared Miracle Curios Barnabas",
        breed: "Devon Rex",
        dob: "04 мая 2024 г.",
        price: "50 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph06.jpeg",
                alt: "ph13",
            },
            {
                id: 1,
                src: "photo/ph24.jpeg",
                alt: "ph24",
            },
            {
                id: 2,
                src: "photo/ph25.jpeg",
                alt: "ph25",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-06",
        sex: "MALE"
    },
];

export const breedingAnimalList: Card[] = [
    {
        id: 1,
        name: "Big Eared Miracle Afrodita",
        breed: "Devon Rex",
        dob: "17 июля 2023 г.",
        price: "40 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph202412060011.jpeg",
                alt: "ph01",
            },
            {
                id: 1,
                src: "photo/ph02.jpeg",
                alt: "ph02",
            },
            {
                id: 2,
                src: "photo/ph03.jpeg",
                alt: "ph03",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-01",
        sex: "FEMALE"
    },
    {
        id: 2,
        name: "Ch.WCF Big Eared Miracle Tetta",
        breed: "Devon Rex",
        dob: "04 августа 2022 г.",
        price: "50 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph202412060021.jpeg",
                alt: "ph202412060021",
            },
            {
                id: 1,
                src: "photo/ph20.jpeg",
                alt: "ph20",
            },
            {
                id: 2,
                src: "photo/ph21.jpeg",
                alt: "ph21",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-02",
        sex: "FEMALE"
    },
    {
        id: 3,
        name: "Big Eared Miracle Curios Salеy",
        breed: "Devon Rex",
        dob: "04 мая 2024 г.",
        price: "50 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph20241206003.jpeg",
                alt: "ph20241206003",
            },
            {
                id: 1,
                src: "photo/ph20.jpeg",
                alt: "ph20",
            },
            {
                id: 2,
                src: "photo/ph21.jpeg",
                alt: "ph21",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-02",
        sex: "FEMALE"
    },
    {
        id: 4,
        name: "Polly Pekcanna",
        breed: "Devon Rex",
        dob: "15 марта 2024 г.",
        price: "50 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph202412060041.jpeg",
                alt: "ph202412060041",
            },
            {
                id: 1,
                src: "photo/ph20.jpeg",
                alt: "ph20",
            },
            {
                id: 2,
                src: "photo/ph21.jpeg",
                alt: "ph21",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-02",
        sex: "FEMALE"
    },
    {
        id: 5,
        name: "Ch.WCF Big Eared Miracle Afanasij",
        breed: "Devon Rex",
        dob: "17 июля 2023 г.",
        price: "50 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph202412060061.jpeg",
                alt: "ph202412060061",
            },
            {
                id: 1,
                src: "photo/ph20.jpeg",
                alt: "ph20",
            },
            {
                id: 2,
                src: "photo/ph21.jpeg",
                alt: "ph21",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-02",
        sex: "MALE"
    }
]

export const cardList: Card[] = [
    {
        id: 1,
        name: "Big Eared Miracle Bosphor",
        breed: "Devon Rex",
        dob: "30 апреля 2024 г.",
        price: "40 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph01.jpeg",
                alt: "ph01",
            },
            {
                id: 1,
                src: "photo/ph02.jpeg",
                alt: "ph02",
            },
            {
                id: 2,
                src: "photo/ph03.jpeg",
                alt: "ph03",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-01",
        sex: "MALE"
    },
    {
        id: 2,
        name: "Big Eared Miracle Sally",
        breed: "Devon Rex",
        dob: "01 мая 2024 г.",
        price: "50 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph06.jpeg",
                alt: "ph06",
            },
            {
                id: 1,
                src: "photo/ph20.jpeg",
                alt: "ph20",
            },
            {
                id: 2,
                src: "photo/ph21.jpeg",
                alt: "ph21",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-02",
        sex: "MALE"
    },
    {
        id: 3,
        name: "Big Eared Miracle Xsavier",
        breed: "Devon Rex",
        dob: "01 марта 2023 г.",
        price: "В добрые руки",
        photos: [
            {
                id: 0,
                src: "photo/ph07.jpeg",
                alt: "ph07",
            },
            {
                id: 1,
                src: "photo/ph22.jpeg",
                alt: "ph22",
            }
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-03",
        sex: "MALE"
    },
    {
        id: 4,
        name: "Big Eared Miracle Zlata",
        breed: "Devon Rex",
        dob: "20 марта 2024 г.",
        price: "В добрые руки",
        photos: [
            {
                id: 0,
                src: "photo/ph09.jpeg",
                alt: "ph09",
            },
            {
                id: 1,
                src: "photo/ph23.jpeg",
                alt: "ph23",
            }
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-04",
        sex: "MALE"
    },
    {
        id: 5,
        name: "Big Eared Miracle Zara",
        breed: "Devon Rex",
        dob: "20 марта 2024 г.",
        price: "В добрые руки",
        photos: [
            {
                id: 0,
                src: "photo/ph10.jpeg",
                alt: "ph10",
            },
            {
                id: 1,
                src: "photo/ph11.jpeg",
                alt: "ph11",
            },
            {
                id: 2,
                src: "photo/ph12.jpeg",
                alt: "ph12",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-05",
        sex: "MALE"
    },
    {
        id: 6,
        name: "Big Eared Miracle Semen",
        breed: "Devon Rex",
        dob: "01 мая 2024 г.",
        price: "50 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph13.jpeg",
                alt: "ph13",
            },
            {
                id: 1,
                src: "photo/ph24.jpeg",
                alt: "ph24",
            },
            {
                id: 2,
                src: "photo/ph25.jpeg",
                alt: "ph25",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-06",
        sex: "MALE"
    },
    {
        id: 7,
        name: "Forest Miracle Uma",
        breed: "Devon Rex",
        dob: "23 июня 2019 г.",
        price: "В добрые руки",
        photos: [
            {
                id: 0,
                src: "photo/ph14.jpeg",
                alt: "ph14",
            },
            {
                id: 1,
                src: "photo/ph15.jpeg",
                alt: "ph15",
            },
            {
                id: 2,
                src: "photo/ph26.jpeg",
                alt: "ph26",
            }
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-07",
        sex: "MALE"
    },
    {
        id: 8,
        name: "Forest Miracle Shanel",
        breed: "Devon Rex",
        dob: "13 июля 2022 г.",
        price: "В добрые руки",
        photos: [
            {
                id: 0,
                src: "photo/ph16.jpeg",
                alt: "ph16",
            },
            {
                id: 1,
                src: "photo/ph17.jpeg",
                alt: "ph17",
            }
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-08",
        sex: "MALE"
    },
    {
        id: 9,
        name: "Big Eared Miracle Forbs",
        breed: "Devon Rex",
        dob: "05 мая 2024 г.",
        price: "55 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph18.jpeg",
                alt: "ph18",
            },
            {
                id: 1,
                src: "photo/ph19.jpeg",
                alt: "ph19",
            },
            {
                id: 2,
                src: "photo/ph28.jpeg",
                alt: "ph28",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-09",
        sex: "MALE"
    },
    {
        id: 10,
        name: "Big Eared Miracle Dardanella",
        breed: "Devon Rex",
        dob: "05 мая 2024 г.",
        price: "60 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph27.jpeg",
                alt: "ph27",
            },
            {
                id: 1,
                src: "photo/ph28.jpeg",
                alt: "ph28",
            },
            {
                id: 2,
                src: "photo/ph29.jpeg",
                alt: "ph29",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-10",
        sex: "MALE"
    },
    {
        id: 11,
        name: "ch.WCF Big Eared Miracle Radja",
        breed: "Devon Rex",
        dob: "25 апреля 2022 г.",
        price: "30 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph32.jpeg",
                alt: "ph32",
            },
            {
                id: 1,
                src: "photo/ph30.jpeg",
                alt: "ph30",
            },
            {
                id: 2,
                src: "photo/ph31.jpeg",
                alt: "ph31",
            }
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-11",
        sex: "MALE"
    },
  ]
