const latSymbol: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const latLowSymbol: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".toLowerCase();
const numSymbol: string = "0123456789";
const specSymbol: string = "!@#$%&*+-=_?";
const allowedSymbols = latSymbol + latLowSymbol + numSymbol + specSymbol;

export function PasswordCheck1(password:string): boolean {
    return (password.length >= 12 && password.length <= 32);
}

export function PasswordCheck2(password:string): boolean {
    for (let i = 0; i < password.length; i++ ) {
        if (latSymbol.includes(password[i],0)) {
            return true;
        }
    }
    return false;
}

export function PasswordCheck3(password:string): boolean {
    for (let i = 0; i < password.length; i++ ) {
        if (latLowSymbol.includes(password[i],0)) {
            return true;
        }
    }
    return false;
}

export function PasswordCheck4(password:string): boolean {
    for (let i = 0; i < password.length; i++ ) {
        if (numSymbol.includes(password[i],0)) {
            return true;
        }
    }
    return false;
}

export function PasswordCheck5(password:string): boolean {
    for (let i = 0; i < password.length; i++ ) {
        if (specSymbol.includes(password[i],0)) {
            return true;
        }
    }
    return false;
}

export function PasswordCheck6(password:string): boolean {
    if (password.length === 0) return false;
    for (let i = 0; i < password.length; i++ ) {
        if ( !allowedSymbols.includes(password[i],0) ) {
            return false;
        }
    }
    return true;
}

export function PasswordCheck7(password:string, reEnteredPassword:string): boolean {
    if (password === reEnteredPassword) {
        return true;
    }  else {
        return false;
    }  
}

export function GeneralCheck(password:string, reEnteredPassword:string): boolean {
    return PasswordCheck1(password) && PasswordCheck2(password) &&
    PasswordCheck3(password) && PasswordCheck4(password) &&
    PasswordCheck5(password) && PasswordCheck6(password) && 
    PasswordCheck7(password, reEnteredPassword);
}
