import { Advert } from "./HomePage";

interface AdvertiserCardProps {
    advertiserCard: Advert;
}

export default function Advertiser(props:AdvertiserCardProps): JSX.Element {
    return (
        <>
            <div className="card_item">
                {props.advertiserCard.name}
                <div className="card_item_text">text</div>
                <button className="button_adv_more">Сайт рекламы</button>
            </div>
            
        </>
    );
}