import PetShortCard from "./PetShortCard";
import Advertiser from "./Advertiser";
import { useEffect, useState } from "react";
import PetFullCardPage from "./PetFullCardText";
import { useSelector } from "react-redux";
/*import { cardList } from "./DebugData/DebugCardList";*/
import { advertiserList } from "./DebugData/DebugAdvertiserList";
import TelephoneNumberHiden from "./TelephoneNumberHiden";
import PetFullCardText from "./PetFullCardText";

export type Photo = {
    id: number,
    src: string,
    alt: string,
}

export type CatPhoto = {
    id: number,
    cat: number,
    path_file: string
}

export interface Card {
    sex: 'MALE' | 'FEMALE';
    id: number;
    name: string;
    breed: string;
    dob: string; 
    price: string;
    photos: Array<Photo>;
    description: string;
    telNumber: string;
}

export interface CatCard {
    id: number;
    user: number;
    breed: string;
    name: string;
    sir_id: number;
    sir_name: string;
    sir_coat: string;
    dam_id: number;
    dam_name: string;
    dam_coat: string;
    birthday: null | string,
    pet_price: string;
    breed_price: string;
    coat: string;
    sex: 'MALE' | 'FEMALE';
    sale_status:  'SALE' | 'SOLD' | 'RESERVED' | 'NOT_SALE'
    is_neuter: boolean;
    adv_text: string;
    tel_number: string;
    photos: Array<CatPhoto>;
    }

export interface Advert {
    id: number;
    name: string;
    url: string;
}

interface ItemShowObject {
        kind: string;
        id: number;
    }

export default function HomePage(): JSX.Element {
    const paginationServer: number = 3;

    const [showPetFullCardID, setFullCardID] = useState<number>(0);
    const [showChangeFilter, setShowChangeFilter] = useState<boolean>(false);
    const [activePhoto, setActivePhoto] = useState<number>(0);

    const [paginationLastPage, setPaginationLastPage] = useState<number>(0);
    const [paginationActivePage, setPaginationActivePage] = useState<number>(1);
    const [urlPreviousPage, setURLPreviousPage] = useState<string | null>(null);
    const [urlNextPage, setURLNextPage] = useState<string | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [catCardList, setCardList] = useState<CatCard[]>([]);

    const [debugLabel, setDebugLabel] = useState<number>(0);
    const [debugLabelString, setDebugLabelString] = useState<string>("");

    const {sectionID} = useSelector((state: any) => state.section);
    const { userIsAuthenticated } = useSelector((state:any) => state.user);

    const mixList: ItemShowObject[] = getMixList(catCardList, advertiserList);

    function setPetFullCardID (id: number) {
        if (getPetCard(id).photos.length > 0) {
            setActivePhoto(getPetCard(id).photos[0].id);
        } else {
            setActivePhoto(0);
        }
        setFullCardID(id);
    }

    function ruleAddAdv (i: number): Boolean {
        const freq = 5;
        if (i%freq === 0) {
            return true;
        }
        return false;
    }

    function getMixList(cardList: Array<CatCard>, advertList: Array<Advert>): Array<ItemShowObject> {
        let rizultList: Array<ItemShowObject> = [];
        let i: number = 0;
        let j: number = 0;
        let item: ItemShowObject;
        while (i<cardList.length) {
            item = { kind: "c", id: cardList[i].id,}
            rizultList.push(item);
            if (ruleAddAdv(i)) {
                if (j<advertList.length) {
                    item = { kind: "a", id: advertList[j].id,}
                    rizultList.push(item);
                    j++;
                }
            }
            i++;
        }

        return rizultList;
    }

    function getAdvertiserCard(id: number): Advert {
        let item: Advert = { id: 0, name: "", url: "",};
        for (let i=0; i<advertiserList.length; i++) {
            if (id===advertiserList[i].id) {
                return advertiserList[i];
            }
        }
        return item;
    }

    function getAdvertiserURL(id: number): string {
        for (let i=0; i<advertiserList.length; i++) {
            if (id===advertiserList[i].id) {
                return advertiserList[i].url;
            }
        }
        return "";
    }

    function getPetCard(id: number): CatCard {
        let item: CatCard = {
            id: 0,
            user: 0,
            breed: "",
            name: "",
            sir_id: 0,
            sir_name: "",
            sir_coat: "",
            dam_id: 0,
            dam_name: "",
            dam_coat: "",
            /*birthday: null,*/
            birthday: "",
            pet_price: "",
            breed_price: "",
            coat: "",
            sex: "MALE",
            sale_status: "SALE",
            is_neuter: false,
            adv_text: "",
            tel_number: "",
            photos: []
        };
        for (let i=0; i<catCardList.length; i++) {
            if (id===catCardList[i].id) {
                return catCardList[i];
            }
        }
        return item;
    }

    function getPhotoPathFile (idPetCard: number, idPhoto: number): string {
        for (let i=0; i <= getPetCard(idPetCard).photos.length - 1; i++) {
            if (getPetCard(idPetCard).photos[i].id === idPhoto) return getPetCard(idPetCard).photos[i].path_file;
        }
        return "";
    }

    function getPhotoIndex (idPetCard: number, idPhoto: number): number {
        for (let i=0; i <= getPetCard(idPetCard).photos.length - 1; i++) {
            if (getPetCard(idPetCard).photos[i].id === idPhoto) return i;
        }
        return -1;
    }

    function requestCatCardList(url: string, isFirstRecord: boolean = true) {
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки
        
        const replaceHttpWithHttps = (url: string | null): string | null => {
            if (url === null) {
                return url;
            } else {
                return url.replace("http://", "https://");
            }
        };

        const isPageInclude = (url: string | null): boolean => {
            if (url === null) return false;
            return url.includes("?page=");
        }
        
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
  
        fetch(url, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return response.json();
            })
            .then(data => {
                let forPaginationLastPage = Math.ceil(data["count"] / paginationServer);
                let forURLNextPage = replaceHttpWithHttps(data["next"]);
                let forURLPreviousPage = replaceHttpWithHttps(data["previous"]);
                let forPaginationActivePage = 1;
                let forPetFullCardID = 0;
                let forActivePhoto = 0;
                
                let activePageSetting: boolean = false;

                if (!activePageSetting && forURLPreviousPage === null) {
                    forPaginationActivePage=1;
                    activePageSetting = true;
                }

                if (!activePageSetting && !isPageInclude(forURLPreviousPage)) {
                    forPaginationActivePage=2;
                    activePageSetting = true;
                }
                
                if (!activePageSetting && forURLNextPage === null) {
                    forPaginationActivePage=forPaginationLastPage;
                    activePageSetting = true;
                } 
                    
                if (!activePageSetting && forURLNextPage !== null) {
                    forPaginationActivePage=parseInt(forURLNextPage.split("?page=")[1], 10) - 1;
                    activePageSetting = true;
                }
                    
                if (!activePageSetting && forURLPreviousPage !== null) {
                    forPaginationActivePage=parseInt(forURLPreviousPage.split("?page=")[1], 10) + 1;
                    activePageSetting = true;
                }
                
                setCardList([...data["results"]]);
                let arr = data["results"];
                
                if (showPetFullCardID > 0 && catCardList.length > 0) {
                    if (isFirstRecord) {
                        forPetFullCardID = arr[0].id;
                        if (arr[0].photos.length > 0) {
                            forActivePhoto = arr[0].photos[0].id;
                        } 
                    } else {
                        forPetFullCardID = arr[arr.length - 1].id;
                        if (arr[arr.length - 1].photos.length > 0) {
                            forActivePhoto = arr[arr.length - 1].photos[0].id;
                        } 
                    }
                }

                setPaginationLastPage(forPaginationLastPage);
                setURLNextPage(forURLNextPage);
                setURLPreviousPage(forURLPreviousPage);
                setPaginationActivePage(forPaginationActivePage);
                setFullCardID(forPetFullCardID);
                setActivePhoto(forActivePhoto);
            })
            .catch(error => {
                setError(error.message || 'Произошла ошибка');
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    }

    function getPaginationItem(activePage: number, lastPage: number, index: number = 0): number {
        var paginationArr: (number)[] = [0, 0, 0, 0, 0];

        if (lastPage === 2) {
            paginationArr = [-1, -1, -1, -1, -1];
        }

        if (lastPage === 3) {
            paginationArr = [-1, -1, 2, -1, -1];
        }

        if (lastPage === 4) {
            paginationArr = [-1, 2, 3, -1, -1];
        }

        if (lastPage === 5) {
            paginationArr = [-1, 2, 3, 4, -1];
        }

        if (lastPage === 6) {
            paginationArr = [2, 3, 4, -1, 5];
        }

        if (lastPage === 7) {
            paginationArr = [2, 3, 4, 5, 6];
        }

        if (lastPage > 7) {
            if (activePage <= 4) {
                paginationArr = [2, 3, 4, 5, 6];
            } else {
                if (activePage >= lastPage - 3) {
                    paginationArr = [ lastPage - 5, 
                                        lastPage - 4, 
                                        lastPage - 3, 
                                        lastPage - 2, 
                                        lastPage - 1
                                    ];
                } else {
                    paginationArr = [ activePage - 2, 
                        activePage - 1,
                        activePage,
                        activePage + 1,
                        activePage + 2
                    ];
                }
            }
            
            if (paginationArr[0] !== 2) {
                paginationArr[0] = 0;
            }

            if (paginationArr[4] !== lastPage - 1) {
                paginationArr[4] = 0;
            }
        }

        return paginationArr[index];
    }

    function getIndexPetCard(id: number): number {
        let item: CatCard = {
            id: 0,
            user: 0,
            breed: "",
            name: "",
            sir_id: 0,
            sir_name: "",
            sir_coat: "",
            dam_id: 0,
            dam_name: "",
            dam_coat: "",
            birthday: "",
            pet_price: "",
            breed_price: "",
            coat: "",
            sex: "MALE",
            sale_status: "SALE",
            is_neuter: false,
            adv_text: "",
            tel_number: "",
            photos: []
        };
        for (let i=0; i<catCardList.length; i++) {
            if (id===catCardList[i].id) {
                return i;
            }
        }
        return -1;
    }

    const handlerClick = (id: number, kind: string ) => {
        if (kind === "c") {
            setPetFullCardID(id);
        } 
        if (kind === "a") {
            let url = getAdvertiserURL(id);
            window.open(url, '_blank');
        }
    }

    const handlerPrevPage = () => {
        if (urlPreviousPage !== null) {
            requestCatCardList(urlPreviousPage);
        }
    }

    const handlerNextPage = () => {
        if (urlNextPage !== null) {
            requestCatCardList(urlNextPage);
        }
    }

    const handlerSetPage = (num:number) => {
        if (num === paginationActivePage || paginationLastPage=== 1) return;

        if (num > 0 && num <= paginationLastPage) {
            let url: string = "";
            let fulfilled: boolean = false;
            if (urlNextPage !== null) {
                url = urlNextPage.replace(/(\?page=)\d+/, `$1${num}`);
                fulfilled = true;
            } else {
                if (urlPreviousPage !== null) {
                    url = urlPreviousPage.replace(/(\?page=)\d+/, `$1${num}`);
                    fulfilled = true;
                }
            }
            requestCatCardList(url);
            return;
        }
    }

    const handlerPrevRecord = () => {
        let index = getIndexPetCard(showPetFullCardID);
        if (index > 0) {
            setPetFullCardID(catCardList[index-1].id);
        } else {
            if (urlPreviousPage !== null) {
                requestCatCardList(urlPreviousPage, false);
            }
        }
    }

    const handlerNextRecord = () => {
        let index = getIndexPetCard(showPetFullCardID);
        if (index < catCardList.length - 1) {
            setPetFullCardID(catCardList[index+1].id);
        } else {
            if (urlNextPage !== null) {
                requestCatCardList(urlNextPage);
            }
        }
    }

    const handlerPhoto = (id:number) => {
        setActivePhoto(id);
    }

    const handlerPrevPhoto = () => {
        const indexPhoto = getPhotoIndex(showPetFullCardID, activePhoto);
        if (indexPhoto > 0) {
            setActivePhoto(getPetCard(showPetFullCardID).photos[indexPhoto - 1].id);
        } else {
            setActivePhoto(getPetCard(showPetFullCardID).photos[0].id);
        }
    }

    const handlerNextPhoto = () => {
        const indexPhoto = getPhotoIndex(showPetFullCardID, activePhoto);
        if (indexPhoto < getPetCard(showPetFullCardID).photos.length - 1) {
            setActivePhoto(getPetCard(showPetFullCardID).photos[indexPhoto + 1].id);
        } else {
            setActivePhoto(getPetCard(showPetFullCardID).photos[indexPhoto].id);
        }
    }

    const handlerCloseFullPetCardWindow = () => {
        setPetFullCardID(0);
    }

    const handlerCloseErrorWindow = () => {
        setError(null);
    }

    useEffect(() => {
        requestCatCardList('https://mitrapro.tech/cats_withphoto/');
      }, []); // Пустой массив зависимостей указывает на выполнение только при монтировании
    
    const showListViewJSX = mixList.map((item: ItemShowObject) =>
        <div key={item.kind + String(item.id)} onClick={() => handlerClick(item.id, item.kind)}>
            {(item.kind === "c") ? <PetShortCard petCard={getPetCard(item.id)} isFavorite={true}/> : <Advertiser advertiserCard={getAdvertiserCard(item.id)} />}
        </div>
    );

    const paginationItem1JSX: JSX.Element = 
        <>
            <div className={(getPaginationItem(paginationActivePage, paginationLastPage, 0) === 0) ? "not_page_number" : 
                (paginationActivePage === getPaginationItem(paginationActivePage, paginationLastPage, 0)) ? "page_number_active" : 
                                                                                                            "page_number"}
                onClick={() => handlerSetPage(getPaginationItem(paginationActivePage, paginationLastPage, 0))}
            >
                {(getPaginationItem(paginationActivePage, paginationLastPage, 0) > 0) ? getPaginationItem(paginationActivePage, paginationLastPage, 0) : "..."}
            </div>
        </>
    
    const paginationItem2JSX: JSX.Element = 
        <>
            <div className={(paginationActivePage === getPaginationItem(paginationActivePage, paginationLastPage, 1)) ? "page_number_active" : 
                                                                                                                            "page_number"}
                onClick={() => handlerSetPage(getPaginationItem(paginationActivePage, paginationLastPage, 1))}
            >
                {getPaginationItem(paginationActivePage, paginationLastPage, 1)}
            </div>
        </>
    
    const paginationItem3JSX: JSX.Element = 
        <>
            <div className={(paginationActivePage === getPaginationItem(paginationActivePage, paginationLastPage, 2)) ? "page_number_active" : 
                                                                            "page_number"}
                onClick={() => handlerSetPage(getPaginationItem(paginationActivePage, paginationLastPage, 2))}
            >
                {getPaginationItem(paginationActivePage, paginationLastPage, 2)}
            </div>
        </>

    const paginationItem4JSX: JSX.Element = 
        <>
            <div className={(paginationActivePage === getPaginationItem(paginationActivePage, paginationLastPage, 3)) ? "page_number_active" : 
                                                                            "page_number"}
                onClick={() => handlerSetPage(getPaginationItem(paginationActivePage, paginationLastPage, 3))}
            >
                {getPaginationItem(paginationActivePage, paginationLastPage, 3)}
            </div>
        </>

    const paginationItem5JSX: JSX.Element = 
        <>
            <div className={(getPaginationItem(paginationActivePage, paginationLastPage, 4) === 0) ? "not_page_number" :
                (paginationActivePage === getPaginationItem(paginationActivePage, paginationLastPage, 4)) ? "page_number_active" : 
                                                                                                            "page_number"}
                onClick={() => handlerSetPage(getPaginationItem(paginationActivePage, paginationLastPage, 4))}
            >
                {((getPaginationItem(paginationActivePage, paginationLastPage, 4) > 0)) ? getPaginationItem(paginationActivePage, paginationLastPage, 4) : "..."}
            </div>
        </>

    const paginationBlock: JSX.Element = 
        <>
            <div className="pagination_block">
                <div className="pagination_bar">
                    <button className={`paginator_button ${paginationActivePage === 1 ? 'disabled' : ''}`} 
                            disabled={paginationActivePage===1} >
            
                        <img src="button_left.png" alt="Left" 
                            onClick={() => handlerPrevPage()}
                        />
        
                    </button>
                    <div className={(paginationActivePage === 1) ? "page_number_active" : "page_number"}
                        onClick={() => handlerSetPage(1)}
                    >
                        {"1"}
                    </div>
                
                    {(getPaginationItem(paginationActivePage, paginationLastPage, 0) >= 0) && paginationItem1JSX}
                
                    {(getPaginationItem(paginationActivePage, paginationLastPage, 1) >= 0) && paginationItem2JSX}
                
                    {(getPaginationItem(paginationActivePage, paginationLastPage, 2) >= 0) && paginationItem3JSX}
                
                    {(getPaginationItem(paginationActivePage, paginationLastPage, 3) >= 0) && paginationItem4JSX}
                
                    {(getPaginationItem(paginationActivePage, paginationLastPage, 4) >= 0) && paginationItem5JSX}
                
                    <div className={(paginationActivePage === paginationLastPage) ? "page_number_active" : "page_number"}
                        onClick={() => handlerSetPage(paginationLastPage)}
                    >
                        {paginationLastPage}
                    </div>             
                    <button className={`paginator_button ${paginationActivePage === paginationLastPage ? 'disabled' : ''}`} 
                            disabled={paginationActivePage===paginationLastPage} 
                    >
            
                        <img src="button_right.png" alt="Right"
                            onClick={() => handlerNextPage()}
                        />
                    </button>
                </div> 
            </div>
        </>

    const showListJSX: JSX.Element = 
      <>
          { showListViewJSX }
          { (mixList.length > 1 && paginationLastPage > 1) && paginationBlock }
      </>

    const sliderButtonsJSX = getPetCard(showPetFullCardID).photos.map((item: CatPhoto) =>
        <>
            <div key={item.id} 
                className={(activePhoto===item.id) ? "photo_slider_item_active" : "photo_slider_item"} 
                onClick={() => handlerPhoto(item.id)}
            >
            </div>
        </>
    );

    const lockPreviosRecordButton: boolean = getIndexPetCard(showPetFullCardID) === 0 && paginationActivePage === 1;
    const lockNextRecordButton: boolean = getIndexPetCard(showPetFullCardID) === catCardList.length - 1 && paginationActivePage === paginationLastPage;

    const showFullPetCardWindowJSX: JSX.Element = 
        <>
            <div className="full_card_modal_overlay">
                <div className='full_card_modal_content_common'>
                    <div className="full_card_column_button">
                        <div></div>
                        <button 
                            className={`full_card_step_button ${lockPreviosRecordButton ? 'disabled' : ''}`} 
                            disabled={lockPreviosRecordButton}
                            onClick={handlerPrevRecord}
                        >
                            <img src="button_left.png" alt="Left"/>
                        </button>   
                    </div>

                    <div className="full_card">
                        <div className="full_card_image">
                            <img alt={"photo"} 
                                src={(getPetCard(showPetFullCardID).photos.length > 0) ? getPhotoPathFile(showPetFullCardID, activePhoto) : ""} 
                                className="full_photo"
                            />
                
                            {
                                (getPetCard(showPetFullCardID).photos.length>1) ? 
                                    <div className="full_card_button_bar">
                                        <button className="photo_slider_button" 
                                            onClick={() => handlerPrevPhoto()}
                                        >
                                            {(getPetCard(showPetFullCardID).photos.length > 2 && activePhoto > 0) ? "<" : " "}
                                        </button>

                                        {sliderButtonsJSX}
                            
                                        <button className="photo_slider_button" onClick={() => handlerNextPhoto()}>
                                            {(getPetCard(showPetFullCardID).photos.length > 2) && (activePhoto < getPetCard(showPetFullCardID).photos.length - 1) ? ">" : " "}
                                        </button>
                                    </div> : <></>
                            }
                        </div>
                        <div className="full_card_text">
                            <PetFullCardText petCard={getPetCard(showPetFullCardID)} />

                            <TelephoneNumberHiden telNum={getPetCard(showPetFullCardID).tel_number || ''} 
                                telNumHiden={"+Х-ХXX-XXX-XX-XX"} 
                                className={"full_card_telephone_number"} 
                            />
                        </div>
                    </div>                
                      
                    <div className="full_card_column_button">
                        <button className="full_card_close_button"
                            onClick={() => handlerCloseFullPetCardWindow()}
                        >
                            <img src="close_button.png" alt="Left"/>
                        </button>
                        <button                             
                            className={`full_card_step_button ${lockNextRecordButton ? 'disabled' : ''}`} 
                            disabled={lockNextRecordButton}

                            onClick={handlerNextRecord}
                        >
                            <img src="button_right.png" alt="Right"/>
                        </button>
                    </div>
                </div>
            </div>
        </>

    const loaderJSX : JSX.Element =
        <>
            <div className="loader_overlay">
                <span className="loader_orange"></span>
            </div>
        </>

    const errorJSX: JSX.Element =
        <>
            <div className="error_overlay">
                <div className="error_box">
                    <div className="error_text">
                        {error}
                    </div>
                    <button className="full_card_close_button"
                        onClick={() => handlerCloseErrorWindow()}
                    >
                            <img src="close_button.png" alt="Left"/>
                    </button>
                </div>

            </div>
        </>
    
    return (
        <>
            {loading && loaderJSX}
            {error && errorJSX}
            {sectionID===1 && (showPetFullCardID === 0) ? showListJSX : showFullPetCardWindowJSX}
        </>
    );
}

