import { useState } from "react";
import { nurseryArr } from "./DebugData/DebugNurseryList";
import { Nursery } from "./personal/MyNursery";
import { veteranList, nuterList, kittenList, breedingAnimalList } from "./DebugData/DebugCardList";
import { Card } from "./HomePage";
import NurseryPetShortCard from "./NurseryPetShortCard";
import NurseryEvent from "./NurseryEvent";
import { formatDate } from "./lib/FormatData";

export type Event = {
    id: number;
    photo: string;
    date: string;
    title: string;
    description: string;
}

export default function NurseryPage(): JSX.Element {
    function getNurseryName(id:number): string {
        for (let i = 0; i <= nurseryArr.length - 1; i++) {
            if (nurseryArr[i].id === id) {return nurseryArr[i].name}
        }
        return '';
    }
    const achievements: Event[] = [
        {
            id: 10,
            photo: 'photo/ph20241206006.jpeg',
            date: formatDate('2024-10-05', false),
            title: 'Best Opposit Sex на международной выставка кошек WCF в г. Ставрополь',
            description: 'Big Eared Miracle Afanasij лучшее животное противоположного пола выставки. На фото брелок с рыжей кошкой - личный приз от легендарного эксперта Мироновой Ольги Сергеевны за хорошую племенную работу.'
        },
        {
            id: 9,
            photo: 'photo/ph202412060051.jpeg',
            date: formatDate('2024-10-05', false),
            title: 'Best Neuter на международной выставка кошек WCF в г. Ставрополь',
            description: 'Big Eared Miracle Xivier лучший neuter выставки.'
        },
        {
            id: 8,
            photo: 'photo/ph20241206.jpeg',
            date: formatDate('2024-11-30', false),
            title: 'Номинация на Best на международной выставке кошек WCF Winter Cat Show в г. Красногорск',
            description: 'Polly Pekcanna номинирована на Best в классе Junior (на фото третья справа).'
        },
        {
            id: 11,
            photo: 'photo/ph20241206010.jpeg',
            date: formatDate('2022-09-25', false),
            title: '3-е место в WCF ринге на международной выставка кошек WCF в г. Батайск',
            description: 'Big Eared Miracle Radja. Эксперт Бодунов.'
        },
        {
            id: 7,
            photo: 'photo/ph7077.jpeg',
            date: formatDate('2021-10-24', false),
            title: 'Лучшее животное противоположного пола в короткошерстной группе на международной выставке кошек WCF в г. Керчь',
            description: 'Big Eared Miracle Koko Shanel лучшая из кошек в короткошерстной группе на международной выставке кошек WCF в г. Керчь.'
        },
        {
            id: 6,
            photo: 'photo/ph7068.jpeg',
            date: formatDate('2021-10-24', false),
            title: '2-е место в ринге WCF на международной выставке кошек WCF в г. Керчь',
            description: 'Big Eared Miracle Koko Shanel стала второй в WCF ринге.'
        },
        {
            id: 5,
            photo: 'photo/ph7051.jpeg',
            date: formatDate('2021-10-24', false),
            title: '3-е место в ринге WCF на международной выставке кошек WCF в г. Керчь',
            description: 'Big Eared Miracle Koko Shanel стала третьей в WCF ринге.'
        },
        {
            id: 1,
            photo: 'photo/ph1960.jpeg',
            date: formatDate('2016-09-03', false),
            title: 'Номининация на титул Best In ShowBEST IN SHOW 2016 на всемирная выставка кошек WCF в г. Есентуки',
            description: 'Big Eared Miracle Gulia номинирована на титул Best In Show на всемирной выставке кошек WCF 2016 (на фото вторая справа). Главный судья - президент WCF Mrs. Anneliese Hackmann, Germany (AB, WCF).'
        },
        {
            id: 2,
            photo: 'photo/ph1954.jpeg',
            date: formatDate('2016-09-03', false),
            title: '7-е место в ринге WCF на всемирной выставка кошек WCF в г. Есентуки',
            description: 'Big Eared Miracle Gulia занимает 7-е место в ринге WCF.'
        },
        {
            id: 3,
            photo: 'photo/ph1967.jpeg',
            date: formatDate('2016-09-04', false),
            title: 'В десятке сильнейших в ринге WCF на всемирной выставка кошек WCF в г. Есентуки',
            description: 'Big Eared Miracle Gulia входит в призовую десятку ринга WCF. Главное быть в топе!'
        },
        {
            id: 4,
            photo: 'photo/ph1847.jpeg',
            date: formatDate('2016-07-17', false),
            title: 'Первое место в ринге WCF на международной выставка кошек WCF в г. Анапа',
            description: 'Fibbi Fine Blus * BY лучшая в WCF ринге.'
        },
    ];

    const photos: Event[] = [
        {
            id: 1,
            photo: 'photo/ph1934.jpeg',
            date: formatDate('2016-09-03', false),
            title: 'Fibbi Fine Blus * BY в WCF ринге',
            description: 'Эксперт в ринге президент WCF Mrs. Anneliese Hackmann, Germany (AB, WCF)'
        },
        {
            id: 2,
            photo: 'photo/ph202412007.jpeg',
            date: formatDate('2024-10-05', false),
            title: 'Polly Pekcanna у эксперта в WCF ринге на международной выставке WCF в г. Ставрополь',
            description: 'WCF ринг юниоров. Polly умеет себя показать. Эксперт ... '
        },
        {
            id: 3,
            photo: 'photo/ph20241206008.jpeg',
            date: formatDate('2024-10-06', false),
            title: 'Победители WCF ринга на международной выставке WCF в г. Ставрополь',
            description: 'Big Eared Miracle Afrodita (на фото справа) одна из лучших в WCF ринге. Эксперт AB WCF Миронова Ольга Сергеевна.'
        },
    ];
    
    const [showNurseryWindowID, setShowNurseryWindowID] = useState<number>(0);
    
    const handlerClick = (item: number) => {
        setShowNurseryWindowID(item);
    }

    const achievementsJSX = achievements.map((item: Event) =>
        <div key={item.id}>
            <NurseryEvent eventCard={item} />
        </div>
    );

    const photosJSX = photos.map((item: Event) =>
        <div key={item.id}>
            <NurseryEvent eventCard={item} />
        </div>
    );

    const veteranListJSX = veteranList.map((item: Card) =>
        <div key={item.id }>
            <NurseryPetShortCard petCard={item} />
        </div>
    );

    const nuterListJSX = nuterList.map((item: Card) =>
        <div key={item.id }>
            <NurseryPetShortCard petCard={item} />
        </div>
    );

    const kittenListJSX = kittenList.map((item: Card) =>
        <div key={item.id }>
            <NurseryPetShortCard petCard={item} />
        </div>
    );

    const breedingAnimalListJSX = breedingAnimalList.map((item: Card) =>
        <div key={item.id }>
            <NurseryPetShortCard petCard={item} />
        </div>
    );

    const demoNessaryJSX: JSX.Element = 
        <>
            <h1>Питомник {getNurseryName(showNurseryWindowID)}</h1>
            <h2>Племенные животные</h2>
            {breedingAnimalListJSX}
            <h2>Котята</h2>
            {kittenListJSX}
            <h2>Ньютеры</h2>
            {nuterListJSX}
            <h2>Ветераны</h2>
            {veteranListJSX}
            <h2>Достижения</h2>
            {achievementsJSX}
            <h2>Фото</h2>
            {photosJSX}
        </>

    const nurseryWindowJSX: JSX.Element =
        <>
            <div className="nursery_window_overlay">
                <div className="nursery_window_content_common">
                    <button className="full_card_close_button"
                        onClick={() => setShowNurseryWindowID(0)}
                    >
                        <img src="close_button.png" alt="Left"/>
                    </button>
                    
                    { (showNurseryWindowID === 1) ? demoNessaryJSX : 
                        <div>Для питомника {getNurseryName(showNurseryWindowID)} нет данных</div>
                    }

                </div>
            </div>
        </> 

    const nurseryList = nurseryArr.map((item: Nursery) =>
        <div className="nursery">
            <div key={item.id} 
                onClick={() => handlerClick(item.id)}
            >
                <table className="styled-table">
                    <tbody>
                        <tr className="row">
                            <td className="cell1">{'Питомник: '}</td>
                            <td className="cell2">{item.name}</td>
                        </tr>
                        <tr className="row">
                            <td className="cell1">{'Клуб: '}</td>
                            <td className="cell2">{item.club}</td>
                        </tr>
                        <tr className="row">
                            <td className="cell1">{'Заводчик: '}</td>
                            <td className="cell2">{item.breeder}</td>
                        </tr>
                        <tr className="row">
                            <td className="cell1">{'Город: '}</td>
                            <td className="cell2">{item.adress}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );

    return (
        <>  
            {(showNurseryWindowID > 0) ? nurseryWindowJSX : nurseryList}
        </>
    );
}