import { useState } from "react";
import { NavLink } from "react-router-dom";

export default function MainMenu(): JSX.Element {
    return (
        <>
            <NavLink className='menu_item' to="/">Главная</NavLink>
            <NavLink className='menu_item' to="/nursery">{"Питомники (Заводчики)"}</NavLink>
            <NavLink className='menu_item' to="/about_project">О проекте</NavLink>
            <NavLink className='menu_item' to="/contacts">Контакты</NavLink>
        </>
    );
}

/*
            <NavLink className='menu_item' to="/pet_food">Корма</NavLink>
            <NavLink className='menu_item' to="/animal_care">Уход</NavLink>
            <NavLink className='menu_item' to="/grooming">Грумминг</NavLink>
            <NavLink className='menu_item' to="/expo">Выставки</NavLink>
            <NavLink className='menu_item' to="/club">Клубы</NavLink>
            <NavLink className='menu_item' to="/shelter">Приюты</NavLink>
            <NavLink className='menu_item' to="/hotel">Гостиницы</NavLink>
            <NavLink className='menu_item' to="/vet">Ветеринария</NavLink>
            <NavLink className='menu_item' to="/carrier">Перевозчики</NavLink>
            <NavLink className='menu_item' to="/vacancy">Вакансии</NavLink>


*/