import { Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import ContactPage from "./ContactPage";
import NurseryPage from "./NurseryPage";
import VacancyPage from "./VacancyPage";
import VetPage from "./VetPage";
import AnimalCarePage from "./AnimalCarePage";
import PetFoodPage from "./PetFoodPage";
import GroomingPage from "./GroomingPage";
import ExpoPage from "./ExpoPage";
import ClubPage from "./ClubPage";
import ShelterPage from "./ShelterPage";
import HotelPage from "./HotelPage";
import СarrierPage from "./СarrierPage";
import MyNursery from "./personal/MyNursery";
import MyAnnouncements from "./personal/MyAnnouncements";
import TechSupport from "./personal/TechSupport";
import AboutProjectPage from "./AboutProgectPage";
import Page404 from "./Page404";

export default function Content(): JSX.Element {

    return (
        <>      
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/nursery" element={<NurseryPage />} />
                <Route path="/about_project" element={<AboutProjectPage />} />
                <Route path="/contacts" element={<ContactPage />} />
                <Route path='*' element={<Page404 />} />
            </Routes>
        </>
    );
}

/*
                <Route path="/pet_food" element={<PetFoodPage />} />
                <Route path="/animal_care" element={<AnimalCarePage />} />
                <Route path="/grooming" element={<GroomingPage />} />
                <Route path="/expo" element={<ExpoPage />} />
                <Route path="/club" element={<ClubPage />} />
                <Route path="/shelter" element={<ShelterPage />} />
                <Route path="/hotel" element={<HotelPage />} />
                <Route path="/vet" element={<VetPage />} />
                <Route path="/carrier" element={<СarrierPage />} />
                <Route path="/vacancy" element={<VacancyPage />} />

                <Route path="/my_nursery" element={<MyNursery />} />
                <Route path="/my_announcements" element={<MyAnnouncements />} />
                <Route path="/tech_support" element={<TechSupport />} />

*/