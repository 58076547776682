
import React, { ReactNode, useState } from 'react';
import './LoginModal.css';
import { useDispatch, useSelector } from 'react-redux';
import setAuthenticatedMode from '../redux/authMode';
import { User } from '../AppDev';
import { setUserLogin } from '../redux/authUser';
import { GeneralCheck, PasswordCheck1, PasswordCheck2, PasswordCheck3, PasswordCheck4, PasswordCheck5, PasswordCheck6, PasswordCheck7 } from './CheckPasswordFunc';
import CheckPasswordItem from './CheckPasswordItem';

type UserRole = 'BUYER' | 'BREEDER';

type ModalMode = 'LOGIN' | 'LOGIN_CONFIRMATION' | 
                    'REGISTRATION' | 'RECOVERY_PASSWORD' | 
                    'REGISTRATION_CONFIRMATION' | 'RECOVERY_PASSWORD_CONFIRMATION';

type CheckPwdItem = {text: string, func: (pwd:string, pwd2?:string)=>boolean }
type CheckPasswordArray = Array<CheckPwdItem>

export default function LoginModal(): JSX.Element {
    const dispatch = useDispatch();
    const { authenticatedMode } = useSelector((state: any) => state.authenticatedMode);

    const closeModal = () => {
        setAuthenticatedMode (dispatch) (false);
    };

    const debugUser: User = { 
        userID: 0,
        userLogin: "",
        userNic: "",
        userToken: "",
        userIsAuthenticated: false,
    };

    const [isLoginMode, SetLoginMode] = useState(true);
    const [isRecoverPasswordMode, SetRecoverPasswordMode] = useState(false);
    const [registrationMode, SetRegistrationMode] = useState(false);
    const [[confirmationMode, userConfirmation], SetConfirmationMode] = useState([false, debugUser]);
    const [activeMode, setMode] = useState<ModalMode>('LOGIN');
    const [activeUser, setActiveUser] = useState<User>(debugUser);
    const [statusMessage, setStatusMessage] = useState<string>("");
    const [userLogin, setInputUserLogin] = useState<string>("");
    const [userLoginRegistration, setInputUserLoginRegistration] = useState<string>("");
    const [userLoginRecover, setInputUserLoginRecover] = useState<string>("");
    const [userPassword, setInputUserPassword] = useState<string>("");
    const [userPasswordRegistration, setInputUserPasswordRegistration] = useState<string>("");
    const [userConfirmationPassword, setInputUserConfirmationPassword] = useState<string>("");
    const [role, setRole] = useState<UserRole>('BUYER');
    const [confirmationCode, setInputConfirmationCode] = useState<string>("");
    const [confirmationRegistrationCode, setInputConfirmationRegistrationCode] = useState<string>("");
    const [confirmationRecoveryPasswordCode, setInputConfirmationRecoveryPasswordCode] = useState<string>("");
    const [registrationCode, setInputRegistrationCode] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [acception, setAcception] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    
    const conditionArr = [
        {
            text: "1) Длина пароля от 12 до 32 символов;",
            func: PasswordCheck1(userPasswordRegistration)
        },
        {
            text: "2) Обязательно должны быть большие латинские буквы;",
            func: PasswordCheck2(userPasswordRegistration)
        },
        {
            text: "3) Обязательно должны быть маленькие латинские буквы;",
            func: PasswordCheck3(userPasswordRegistration)
        },
        {
            text: "4) Обязательно должны быть цифры;",
            func: PasswordCheck4(userPasswordRegistration)
        },
        {
            text: "5) Обязательно должны быть символы: '!@#$%&*+-=_?';",
            func: PasswordCheck5(userPasswordRegistration)
        },
        {
            text: "6) Не должно быть иных символов, кроме как указанных в пунктах 1-5;",
            func: PasswordCheck6(userPasswordRegistration)
        },
        {
            text: "7) Новый пароль введённый первоначально должен совпадать с повторно введённым новым паролем.",
            func: PasswordCheck7(userPasswordRegistration, userConfirmationPassword)
        },
    ]

    const showListConditionJSX = conditionArr.map((item) =>
        <CheckPasswordItem text={item.text} status={item.func} />
    );

    const handlerAutorization = async (event: React.FormEvent): Promise<void> => {
        event.preventDefault()
        
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        const user: User = { 
            userID: 1,
            userNic: "",
            userLogin: userLogin,
            userToken: "",
            userIsAuthenticated: false,
        };
        
        const request = {
            login: userLogin,
            password: userPassword,
            action: "LOGIN"
        };
      
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };
  
        /* "http://5.35.91.24:8000/api/messages/" */
        fetch('https://mitrapro.tech/deb_authorization/user_authorization/', requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return response.json();
            })
            .then(data => {
                setStatusMessage(data["message"]);
                if (data["success"]) {
                    setMode('LOGIN_CONFIRMATION');
                    setActiveUser(user);
                }
            })
            .catch(error => {
                /*setStatusMessage(error.message);*/
                setError(error.message || 'Произошла ошибка');
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    }

    const handlerPasswordRecovery = async (event: React.FormEvent): Promise<void> => {
        event.preventDefault()
        
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        const user: User = { 
            userID: 1,
            userNic: "",
            userLogin: userLoginRecover,
            userToken: "",
            userIsAuthenticated: false,
        };
        
        const request = {
            login: userLoginRecover,
            action: "CHANGE_PASSWORD"
        };
      
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };
  
        /* "http://5.35.91.24:8000/api/messages/" */
        fetch('https://mitrapro.tech/deb_authorization/user_password_recovery_get_code/', requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return response.json();
            })
            .then(data => {
                setStatusMessage(data["message"]);
                if (data["success"]) {
                    setMode('RECOVERY_PASSWORD_CONFIRMATION');
                    setActiveUser(user);
                }
            })
            .catch(error => {
                /*setStatusMessage(error.message);*/
                setError(error.message || 'Произошла ошибка');
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    }

    const handlerConfirmation = async (event: React.FormEvent): Promise<void> => {
        event.preventDefault()

        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        var user: User = { 
            userID: 0,
            userNic: userLogin,
            userLogin: userLogin,
            userToken: "",
            userIsAuthenticated: false,
        };

        const request = {
            login: userLogin,
            confirmation_code: confirmationCode
        };

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };

        fetch('https://mitrapro.tech/deb_authorization/user_confirmation_authorization/', requestOptions)
            .then((response) => {
                if (response.ok) {
                    setInputConfirmationCode("");
                } else {
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return response.json();
            })
            .then(data => {
                setStatusMessage(data["message"]);
                if (data["success"]) {
                    user["userToken"] = data["token"];
                    if (user["userToken"].length >= 40) {
                        user["userIsAuthenticated"] = true;
                    }
                    user["userNic"] = data["nic"]
                        
                    localStorage.setItem('authToken', data["token"]);
                    localStorage.setItem('nicName', user["userNic"]);
                    localStorage.setItem('userLogin', user["userLogin"]);
                    
                    setActiveUser(user);
                    setMode('LOGIN');

                    setUserLogin (dispatch) (user);
        
                    setAuthenticatedMode (dispatch) (false);
                }
            })
            .catch(error => {
                /*setStatusMessage(error.message);*/
                setError(error.message || 'Произошла ошибка');
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    }

    const handlerConfirmationRecoveryPassword = async (event: React.FormEvent): Promise<void> => {
        event.preventDefault()

        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        var user: User = { 
            userID: 0,
            userNic: userLoginRecover,
            userLogin: userLoginRecover,
            userToken: "",
            userIsAuthenticated: false,
        };

        const request = {
            login: userLoginRecover,
            action: "CHANGE_PASSWORD",
            confirmation_code: confirmationRecoveryPasswordCode
        };

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };

        fetch('https://mitrapro.tech/deb_authorization/user_password_recovery/', requestOptions)
            .then((response) => {
                if (response.ok) {
                    setInputConfirmationCode("");
                } else {
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return response.json();
            })
            .then(data => {
                setStatusMessage(data["message"]);
                if (data["success"]) {
                    setActiveUser(user);
                    setMode('LOGIN');

                    setUserLogin (dispatch) (user);
                    setInputUserLogin(userLoginRecover);
                }
            })
            .catch(error => {
                /*setStatusMessage(error.message);*/
                setError(error.message || 'Произошла ошибка');
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    }

    const handlerRegistration = async (event: React.FormEvent): Promise<void> => {
        event.preventDefault()
        
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        const user: User = { 
            userID: 1,
            userNic: "",
            userLogin: userLoginRegistration,
            userToken: "",
            userIsAuthenticated: false,
        };
        
        const request = {
            login: userLoginRegistration
        };
      
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };
  
        /* "http://5.35.91.24:8000/api/messages/" */
        fetch('https://mitrapro.tech/deb_authorization/user_registration_get_code/', requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return response.json();
            })
            .then(data => {
                setStatusMessage(data["message"]);
                if (data["success"]) {
                    setMode('REGISTRATION_CONFIRMATION');
                    setActiveUser(user);
                    setInputRegistrationCode(data["registration_code"]);
                }
            })
            .catch(error => {
                /*setStatusMessage(error.message);*/
                setError(error.message || 'Произошла ошибка');
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    }

    const handlerConfirmationRegistration = async (event: React.FormEvent): Promise<void> => {
        event.preventDefault()

        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        var user: User = { 
            userID: 0,
            userNic: userLoginRegistration,
            userLogin: userLoginRegistration,
            userToken: "",
            userIsAuthenticated: false,
        };

        const request = {
            login: userLoginRegistration,
            password: userPasswordRegistration,
            action: "REGISTRATION",
            registration_code: registrationCode,
            confirmation_code: confirmationRegistrationCode
        };

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };

        fetch('https://mitrapro.tech/deb_authorization/user_registration/', requestOptions)
            .then((response) => {
                if (response.ok) {
                    setInputConfirmationCode("");
                } else {
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return response.json();
            })
            .then(data => {
                setStatusMessage(data["message"]);
                if (data["success"]) {
                    user["userToken"] = data["token"];
                    if (user["userToken"].length >= 40) {
                        user["userIsAuthenticated"] = true;
                    }
                        
                    localStorage.setItem('authToken', data["token"]);
                    localStorage.setItem('nicName', user["userNic"]);
                    localStorage.setItem('userLogin', user["userLogin"]);
                    
                    setActiveUser(user);
                    setMode('LOGIN');

                    setUserLogin (dispatch) (user);
        
                    setAuthenticatedMode (dispatch) (false);
                }
            })
            .catch(error => {
                /*setStatusMessage(error.message);*/
                setError(error.message || 'Произошла ошибка');
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    }

    const onChangeLogin = (login: string) => {
        setInputUserLogin(login);
        setStatusMessage("");
    }

    const onChangeLoginRecover = (login: string) => {
        setInputUserLoginRecover(login);
    }

    const onChangeLoginRegistration = (login: string) => {
        setInputUserLoginRegistration(login);
        setStatusMessage("");
    }

    const onChangePassword = (password: string) => {
        setInputUserPassword(password);
        setStatusMessage("");
    }

    const onChangePasswordRegistration = (password: string) => {
        setInputUserPasswordRegistration(password);
        setStatusMessage("");
    }

    const onChangeConfirmationPassword = (password: string) => {
        setInputUserConfirmationPassword(password);
        setStatusMessage("");
    }

    const selectorMode: JSX.Element = 
        <>
            <div className='selector_mode'>
                <div className={(activeMode==='LOGIN') ? 'selector_mode_item_selected' : 'selector_mode_item' }
                    onClick={() => setMode('LOGIN')}>
                    ВХОД
                </div>
                <div className={(activeMode==='REGISTRATION') ? 'selector_mode_item_selected' : 'selector_mode_item' }
                    onClick={() => setMode('REGISTRATION')}>
                    РЕГИСТРАЦИЯ
                </div>
            </div>
        </>
    
    const selectorRecoverPassword: JSX.Element =
        <>
            <div className='selector_recover_password' onClick={() => setMode('RECOVERY_PASSWORD')}>
                Забыли пароль?
            </div>
        </>

    const loginJSX: JSX.Element = 
        <>
            <form  onSubmit={handlerAutorization} >
                <div className="autorization_fields_block" >

                    <label className="autorization_label_fld" htmlFor="loginInput">
                        {"Логин (e-mail)"}
                    </label>
                    <input
                        className="autorization_input_fields"
                        placeholder="login"
                        required
                        type="text"
                        name="login" 
                        maxLength={254}
                        id="loginInput"
                        value={userLogin}
                        onChange={(e) => onChangeLogin(e.target.value)}
                    />

                    <label className="autorization_label_fld" htmlFor="passwordInput">
                        {"Пароль"}
                    </label>
                    <input
                        className="autorization_input_fields"
                        placeholder="Пароль"
                        required
                        type="password"
                        name="password" 
                        id="passwordInput"
                        onChange={(e) => onChangePassword(e.target.value)}
                    />
                </div>
                {error && <p className="error-message">{error}</p>}
                <button className='autorization_entry_button' type='submit'>
                    {loading ? (<span className="loader"></span>) : ("ВОЙТИ")}
                </button>
            </form>
    
            {selectorRecoverPassword}      
        </>

    const confirmationFieldJSX: JSX.Element = 
        <>
            <form  onSubmit={handlerConfirmation} >
                <input
                    className="autorization_input_fields"
                    placeholder="Введите код подтверждения"
                    required
                    type="text"
                    name="confirmCode" 
                    id="confirmCodeInput"
                    onChange={(e) => setInputConfirmationCode(e.target.value)}
                />
                {error && <p className="error-message">{error}</p>}
                <button className="autorization_entry_button" type='submit'>
                    {loading ? (<span className="loader"></span>) : ("Подтвердить")}
                </button>
            </form>
        </>

    const confirmationRegistrationFieldJSX: JSX.Element = 
        <>
            <form  onSubmit={handlerConfirmationRegistration} >
                <input
                    className="autorization_input_fields"
                    placeholder="Введите код подтверждения"
                    required
                    type="text"
                    name="confirmationRegistrationCodeField" 
                    id="confirmationRegistrationCodeFieldInput"
                    onChange={(e) => setInputConfirmationRegistrationCode(e.target.value)}
                />
                {error && <p className="error-message">{error}</p>}
                <button className="autorization_entry_button" type='submit'>
                    {loading ? (<span className="loader"></span>) : ("Подтвердить")}
                </button>
            </form>
        </>

    const confirmationRecoveryPasswordFieldJSX: JSX.Element = 
        <>
            <form  onSubmit={handlerConfirmationRecoveryPassword} >
                <input
                    className="autorization_input_fields"
                    placeholder="Введите код подтверждения"
                    required
                    type="text"
                    name="confirmationRecoveryPasswordField" 
                    id="confirmationRecoveryPasswordField"
                    onChange={(e) => setInputConfirmationRecoveryPasswordCode(e.target.value)}
                />
                {error && <p className="error-message">{error}</p>}
             <button className="autorization_entry_button" type='submit'>
                    {loading ? (<span className="loader"></span>) : ("Подтвердить восстановление пароля")}
                </button>
            </form>
        </>

    const registrationFieldJSX: JSX.Element = 
        <>
            <form  onSubmit={handlerRegistration} >
                <div className="autorization_fields_block" >

                    <label className="autorization_label_fld" htmlFor="loginRegistrationInput">
                        {"Логин (e-mail)"}
                    </label>
                    <input
                        className="autorization_input_fields"
                        placeholder="login"
                        required
                        type="text"
                        name="login" 
                        maxLength={254}
                        id="loginRegistrationInput"
                        onChange={(e) => onChangeLoginRegistration(e.target.value)}
                    />

                    <label className="autorization_label_fld" htmlFor="roleInput">
                        {"Кто Вы?"}
                    </label>
                    <select
                        className="autorization_input_fields"
                        id="roleInput"
                        onChange={(e) => setRole(e.target.value as UserRole)}
                        required
                    >
                        <option className="autorization_input_fields" value="BUYER">Покупатель</option>
                        <option className="autorization_input_fields" value="BUYER">Просто посмотреть</option>
                        <option className="autorization_input_fields" value="BREEDER">Заводчик</option>
                    </select>

                    <label className="autorization_label_fld_button" htmlFor="passwordInputRegistrationField" onClick={() => setShowPassword(!showPassword)}>
                        {(showPassword) ? "Пароль(Скрыть)" : "Пароль(Показать)" }
                    </label>
                    <input
                        className="autorization_input_fields"
                        placeholder="Пароль"
                        required
                        type={(showPassword) ? "text" : "password" }
                        name="passwordRegistration" 
                        id="passwordInputRegistrationField"
                        onChange={(e) => onChangePasswordRegistration(e.target.value)}
                    />
                    <label className="autorization_label_fld" htmlFor="repeatPasswordInput">
                        {"Повторите пароль"}
                    </label>
                    <input
                        className="autorization_input_fields"
                        placeholder="Пароль ещё раз"
                        required
                        type={(showPassword) ? "text" : "password" }
                        name="repeatPassword" 
                        id="repeatPasswordInput"
                        onChange={(e) => onChangeConfirmationPassword(e.target.value)}
                    />
                </div>
                
                {showListConditionJSX}
                
                <label className="custom-checkbox">
                    <input type="checkbox" id="terms" name="terms" required onClick={() => setAcception(!acception)}/>
                    <span className="checkmark"></span>С правилами пользования площадкой ознакомлен и согласен, обязуюсь соблюдать.
                </label>

                <button className='autorization_entry_button' 
                    type='submit' disabled={!(GeneralCheck(userPasswordRegistration, userConfirmationPassword) && acception)}>
                    {loading ? (<span className="loader"></span>) : ("ЗАРЕГИСТРИРОВАТЬСЯ")}
                </button>
            </form>
            <div className='login_status_message'>
                {statusMessage}
            </div>
        </>

    const recoverPasswordFieldJSX: JSX.Element = 
        <>
            <form  onSubmit={handlerPasswordRecovery} >
                <div className="autorization_fields_block" >

                    <label className="autorization_label_fld" htmlFor="loginInputRecover">
                        {"Логин (e-mail)"}
                    </label>
                    <input
                        className="autorization_input_fields"
                        placeholder="login"
                        required
                        type="text"
                        name="login" 
                        maxLength={254}
                        id="loginInputRecover"
                        onChange={(e) => onChangeLoginRecover(e.target.value)}
                    />
                </div>
                {error && <p className="error-message">{error}</p>}
                <button className='autorization_entry_button' type='submit'>
                    {loading ? (<span className="loader"></span>) : ("ВОССТАНОВИТЬ ПАРОЛЬ")}
                </button>
            </form>
        </>

    return (
        <>
            <div className="modal-overlay">
                <div className='modal_content_common'>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className='modal_content_scroll'>
                            { (activeMode==='LOGIN' || activeMode==='REGISTRATION') && (selectorMode)}
                            <div className='login_status_message'>
                                {statusMessage}
                            </div>
                            { (activeMode==='LOGIN') && (loginJSX)}
                            { (activeMode==='LOGIN_CONFIRMATION') && (confirmationFieldJSX)}
                            { (activeMode==='REGISTRATION') && (registrationFieldJSX)}
                            { (activeMode==='RECOVERY_PASSWORD') && (recoverPasswordFieldJSX)}
                            { (activeMode==='REGISTRATION_CONFIRMATION') && (confirmationRegistrationFieldJSX)}
                            { (activeMode==='RECOVERY_PASSWORD_CONFIRMATION') && (confirmationRecoveryPasswordFieldJSX)}
                        </div>
                    </div>
                    <button className="modal-close" onClick={closeModal}>
                        ×
                    </button>
                </div>  
            </div>
        </>
    );
}

/*
                <button className='autorization_entry_button' 
                    type='submit' disabled={!GeneralCheck(userPasswordRegistration, userConfirmationPassword) && acception}>
                    ЗАРЕГИСТРИРОВАТЬСЯ
                </button>

                <label className='acception'>
                    <input className='check_field' type="checkbox" id="terms" name="terms" required/>
                    <div className='acception_text'>С правилами пользования площадкой ознакомлен и согласен, обязуюсь соблюдать.</div>
                </label>

*/
