export function getStringValue (value: string | null): string {
    return value ? value.trim() : ''
}

export function formatPrice(price: string, localString: string = "ru-RU"): string {
    const numericValue = parseFloat(price);
    if (isNaN(numericValue)) {
        return price;
    }
    return numericValue.toLocaleString(localString, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export function getSex(sex: string, isNueter: boolean): string {
    if (sex.trim().length == 0) {
        return "";
    } else {
        if (isNueter) return sex + '/NEUTER'
        return sex;
    }
}

export function formatDate(dateString: string, isAge: boolean = true, localString: string = "ru-RU" ): string {
    const isValidDate = (dateString: string): boolean => {
        const date = new Date(dateString);
      
        // Проверяем, является ли дата объектом Date и не "Invalid Date"
        return !isNaN(date.getTime());
    };
    
    if (isValidDate(dateString)) {
        const date = new Date(dateString); // Преобразуем строку в объект Date
        const ageString = isAge ? ` (${calculateAge(dateString)})` : "";

        return new Intl.DateTimeFormat(localString, {
          day: "2-digit",    // День с ведущим нулём
          month: "long",     // Полное название месяца
          year: "numeric",   // Полное отображение года
        }).format(date) + ageString
    } else {
        return '';
    }
};

export function calculateAge(birthDateString: string): string {
    // Вспомогательная функция для склонения "месяц"
    const getMonthsLabel = (months: number): string => {
        if (months === 1) return "месяц";
        if (months >= 2 && months <= 4) return "месяца";
        return "месяцев";
    };
      
    // Вспомогательная функция для склонения "год"
    const getYearsLabel = (years: number): string => {
        const lastDigit = years % 10;
        const lastTwoDigits = years % 100;
      
        if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
          return "лет";
        }
      
        if (lastDigit === 1) {
          return "год";
        }
        if (lastDigit >= 2 && lastDigit <= 4) {
          return "года";
        }
      
        return "лет";
    };
    
    const today = new Date();
    const birthDate = new Date(birthDateString);
  
    // Проверяем корректность даты
    if (isNaN(birthDate.getTime())) {
      return "";
    }
  
    // Вычисляем разницу в годах
    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();
  
    // Учитываем месяц и день рождения
    if (
      months < 0 || 
      (months === 0 && today.getDate() < birthDate.getDate())
    ) {
      years--;
      months += months < 0 ? 12 : 0;
    }
  
    if (years < 1) {
      // Возраст в месяцах, если меньше года
      return `${months} ${getMonthsLabel(months)}`;
    }
  
    // Возраст в годах, если больше года
    return `${years} ${getYearsLabel(years)}`;
};
  
