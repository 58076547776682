import { User } from "../AppDev";
import { SET_SECTION } from "./actions";

const initialState = {
  sectionID: 1,
};

type ActionSection = {
    type: string,
    payload: number
}

const sectionReducer = (state = initialState, action: ActionSection) => {
  switch (action.type) {
    case SET_SECTION:
      return {
        ...state,
        sectionID: action.payload
      }
    default:
      return state;
  }
};

export default sectionReducer
