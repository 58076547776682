import { useSelector } from "react-redux";
import { CatCard } from "./HomePage";
import { calculateAge, formatDate, formatPrice, getStringValue } from "./lib/FormatData";
import { Parameter } from "./PetFullCardText";

interface PetShortCardProps {
    petCard: CatCard;
    isFavorite: boolean;
}

export default function PetShortCard(props: PetShortCardProps): JSX.Element {
    const { userIsAuthenticated } = useSelector((state:any) => state.user);
    
    const data: Parameter[] = [
        { name: "Кличка",           value: getStringValue(props.petCard.name)},
        { name: "Порода",           value: getStringValue(props.petCard.breed)},
        { name: "Дата рождения",    value: formatDate(getStringValue(props.petCard.birthday))},
        { name: "Цена в любимцы",   value: formatPrice(getStringValue(props.petCard.pet_price))},
    ]

    const cardText = data.map((item) => (
        (item.value !== '') ? <p>{item.value}</p> : <></>
    ));
    
    const buttonFavoriteJSX: JSX.Element =
        <>
            <div><button className="favorite_button">Поместить в избранное</button></div>
        </>
    
    const photo: JSX.Element =
        <>
            {(props.petCard.photos.length > 0) ? 
                <img alt={props.petCard.photos[0].path_file} 
                    src={ props.petCard.photos[0].path_file} 
                    className="card_item_image"
                /> : <></>
            }
        </>

    return (
        <>
            <div className="card_item">
                {photo}

                <div className="card_item_text">
                    {cardText}
                </div>

                <button className="button_more">Подробнее</button>
            </div>
        </>
    );
}

/*
{(userIsAuthenticated && props.isFavorite) ? buttonFavoriteJSX : <></>}  
<button className="button_more2">Подробнее2</button>

*/