import { User } from "../AppDev";
import { SET_USER_LOGIN, SET_USER_LOGOUT } from "./actions";

  var user: User = { 
    userID: 0,
    userNic: "",
    userLogin: "",
    userToken: "",
    userIsAuthenticated: false,
  };

  user["userToken"] = localStorage.getItem("authToken") as string;
  user["userNic"] = localStorage.getItem("nicName") as string;
  user["userLogin"] = localStorage.getItem("userLogin") as string;

  if (typeof(user["userToken"]) === "string") {
    if (user["userToken"].length > 0) {
      user["userIsAuthenticated"] = true;
    }
  }

const initialState: User = {
  userID: 0,
  userNic: user["userNic"],
  userLogin: user["userLogin"],
  userIsAuthenticated: user["userIsAuthenticated"],
  userToken: user["userToken"],
};

type ActionUser = {
  type: string,
  payload: User,
}

const userReducer = (state = initialState, action: ActionUser) => {
  switch (action.type) {
    case SET_USER_LOGIN:
      return {
        ...state,
        userID: action.payload.userID,
        userLogin: "",
        userNic: action.payload.userNic,
        userIsAuthenticated: action.payload.userIsAuthenticated,
        userToken: action.payload.userToken,
      }
    case SET_USER_LOGOUT:
      return {
        ...state,
        userID: 0,
        userLogin: "",
        userNic: "",
        userIsAuthenticated: false,
        userToken: "",
      }
    default:
      return state;
  }
};

export default userReducer
