import { useState } from "react";
import { useSelector } from "react-redux";

interface TelNumProps {
    telNum: string;
    telNumHiden: string;
    className: string;
}
export default function TelephoneNumberHidenAdmin(props:TelNumProps): JSX.Element {
    const [showTelNumber, setShowTelNumber] = useState(false);
    
    const telephoneNumber: string = props.telNum ? props.telNum.trim() : '';
    const telNumHiden: string = (telephoneNumber === "") ? "" : props.telNumHiden

    return (
        <>
            <div className={props.className} onClick={() => setShowTelNumber(!showTelNumber)}>
                {(showTelNumber) ? telephoneNumber : telNumHiden}
            </div>
        </>
    );
}