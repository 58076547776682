import './App.css';
import Geography from './components/Geography';
import Language from './components/Language';
import Login from './components/Login';
import Logo from './components/Logo';

import { useDispatch, useSelector } from 'react-redux';
import ContentPage from './components/ContentPage';
import SectionSelector from './components/SectionSelector';
import { setUserLogin } from './redux/authUser';
import { useState } from 'react';
import MainMenu from './components/MainMenu';
import Footer from './components/Footer';

export type User = {
  userID: number;
  userLogin: string;
  userNic: string;
  userToken: string;
  userIsAuthenticated: boolean;
}

function AppDev(): JSX.Element {
  const dispatch = useDispatch();
  
  const { authenticatedMode } = useSelector((state: any) => state.authenticatedMode);
  const { debugMode } = useSelector((state: any) => state.debugMode);

  const { userNic, userIsAuthenticated } = useSelector((state: any) => state.user);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  
  const empty: JSX.Element = 
    <>
      <div></div>
    </>
  
  const isVisibleBurgerMenu: boolean = false
  const isVisibleGeography: boolean = false
  const isVisibleSectionSelctor: boolean = false
  const isVisibleLanguage: boolean = false
  const isVisibleLogin: boolean = false

  return (
    <>
      <div className='status_bar'>
          <Logo />
          {(isVisibleLogin) && <Login />}
      </div>
      <div className='main_menu'>
        <MainMenu />
      </div>
      <div className='screen'>
        <ContentPage />
        <Footer />
      </div>
    </>
  );
}

export default AppDev;

/*



*/

/*

{(isVisibleGeography) ? <Geography /> : empty}
{(isVisibleSectionSelctor) ? <SectionSelector /> : empty}

{(isVisibleLanguage) ? <Language /> : empty}



  const burgerMenu: JSX.Element = 
    <>
      <div className='burger_menu' onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <span className={isMenuOpen ? "burger-icon open" : "burger-icon"}></span>
        <span className={isMenuOpen ? "burger-icon open" : "burger-icon"}></span>
        <span className={isMenuOpen ? "burger-icon open" : "burger-icon"}></span>
      </div>
    </>
  
  const burgerMenu: JSX.Element = 
    <>
      <div className='burger_menu'>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </>

{(isVisibleBurgerMenu) ? burgerMenu : empty}

*/
