import Content from "./Content";
import MainMenu from "./MainMenu";
import LoginPage from "./LoginPage";
import setAuthenticatedMode from "../redux/authMode";
import { useDispatch, useSelector } from "react-redux";
import Modal from "./Modal";
import LoginModal from "./LoginModal";

export default function ContentPage(): JSX.Element {
  const dispatch = useDispatch();
  const { authenticatedMode } = useSelector((state: any) => state.authenticatedMode);

  return (
    <>      
      {(authenticatedMode) ? <LoginModal /> : <Content />}
    </>
    );
}


/*

      <div className='content'>
        <Content />
      </div>


    return (
        <>      
            { (authenticatedMode) ? <LoginPage /> : modeViewPage }
        </>
    );

*/